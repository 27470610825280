import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import { FormRadioGroup } from '@snsw-gel/react';
import initStepQnA from '../InitialValues/stepQnA';
import FooterNav from '../../FooterNav';
import { yesNoOptions } from '../../../common';
import './Certificates.scss';
import WithStyledLegend from '../../styled/WithStyledLegend';

export default class Certificates extends Component {
    static displayName = 'Certificates';

    static propTypes = {
        previousStep: PropTypes.func,
        nextStep: PropTypes.func,
        nextAction: PropTypes.func,
        isActive: PropTypes.bool,
        currentStep: PropTypes.number
    };

    static defaultProps = {
        previousStep: Function.prototype,
        nextStep: Function.prototype,
        nextAction: Function.prototype,
        isActive: false,
        currentStep: 1
    };

    shouldComponentUpdate (nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState) || !isEqual(this.context, nextContext);
    }

    onSubmit = (values) => {
        const { currentStep } = this.props;
        this.setState({ certificate: values }, () => {
            const stepQnA = initStepQnA[currentStep - 1];

            if (values.documentReplacement) {
                stepQnA.questions[0].answers = [values.documentReplacement === 'true' ? 'Yes' : 'No'];
            }

            this.props.nextAction('certificate', this.state.certificate, currentStep, stepQnA);
            this.props.nextStep();
        });
    };

    render() {
        return (
            <Formik
                initialValues={{ documentReplacement: '' }}
                onSubmit={this.onSubmit}>
                {({ values, handleChange, setFieldValue }) => (
                    <Form className={classNames('form form-certificates', { hidden: !this.props.isActive })}>
                        <div className="form-title">Documents and Licences</div>

                        <WithStyledLegend
                            Component={FormRadioGroup}
                            id="documentReplacement"
                            name="documentReplacement"
                            legendText="Do you need to replace any documents or licences?"
                            helpMessage="Documents and/or licences may include Birth, Marriage or Change of Name certificates, drivers licences, contractor licences, vehicle and/or vessel registration certificates etc."
                            onChange={value => {
                                setFieldValue('documentReplacement', value);
                                handleChange(value);
                            }}
                            value={values.documentReplacement || ''}
                            options={[...yesNoOptions]}
                            vertical
                        />

                        <FooterNav onBackPressed={this.props.previousStep} />
                    </Form>
                )}
            </Formik>
        );
    }
}
