import React, { useState, useEffect } from 'react';
import { CheckboxList } from '@snsw-gel/react';
import './_exclusive-checkbox-group.scss';
import PropTypes from 'prop-types';
import WithStyledLegend from '../styled/WithStyledLegend';

const ExclusiveCheckboxList = ({ ...props }) => {
    const { id, legend, helpMessage, options, formik } = props;
    const { setFieldValue, initialValues } = formik;

    /* eslint-disable security/detect-object-injection */
    const normalizedInitialValues = Object.keys(initialValues).reduce((acc, key) => {
        acc[key] = !!initialValues[key]; // Convert null or falsy values to false
        return acc;
    }, {});

    const [selectedValues, setSelectedValues] = useState(
        options
            .filter(option => option.isChecked || normalizedInitialValues[option.value])
            .map(option => option.value)
    );

    const exclusiveOption = options.find(option => option.exclusive);

    const handleCheckboxChange = (updatedValues) => {
        setSelectedValues(updatedValues);

        if (updatedValues.includes(exclusiveOption?.value)) {
            const filteredValues = [exclusiveOption.value];
            setSelectedValues(filteredValues);
            options.forEach(option =>
                setFieldValue(option.value, option.value === exclusiveOption.value)
            );
        } else {
            const filteredValues = updatedValues.filter(
                value => value !== exclusiveOption?.value
            );
            setSelectedValues(filteredValues);
            options.forEach(option =>
                setFieldValue(option.value, filteredValues.includes(option.value))
            );
        }
    };

    const preparedOptions = options.map(option => ({
        ...option,
        isChecked: selectedValues.includes(option.value),
        disabled: exclusiveOption?.value !== option.value &&
            selectedValues.includes(exclusiveOption?.value)
    }));

    useEffect(() => {
        const normalizedValues = options
            .filter(option =>
                normalizedInitialValues[option.value] ||
                selectedValues.includes(option.value)
            )
            .map(option => option.value);

        setSelectedValues(normalizedValues);
    }, [JSON.stringify(normalizedInitialValues), options]);

    return (
        <WithStyledLegend
            Component={CheckboxList}
            options={preparedOptions}
            value={selectedValues}
            onChange={handleCheckboxChange}
            legendText={legend}
            helpMessage={helpMessage}
            id={id}
        />
    );
};

ExclusiveCheckboxList.propTypes = {
    id: PropTypes.string,
    legend: PropTypes.string,
    helpMessage: PropTypes.string,
    options: PropTypes.array,
    formik: PropTypes.object
};

export default ExclusiveCheckboxList;
