import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { tokens } from '@snsw-gel/react';

export const StyledLink = styled(Link)`
    color: ${tokens.colors.white} !important;
    
    &:visited {
        color: ${tokens.colors.white};
    }
`;
