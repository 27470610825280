import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik, ErrorMessage } from 'formik';
import { withRouter } from 'react-router-dom';
import { SurveyFormApi } from '../SurveyFormApi';
import Header from '../../header/Header';
import AddressInput from '../../address-input/AddressInput';
import { InPageAlert } from '@snsw-gel/react';
import { smoothScrollTo } from '../../../common';
import ChevronSvg from '../../../assets/icons/chevron.svg';
import './PersonalDetailsForm.scss';

class PersonalDetailsForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            manualEntryAddress: false,
            form: {},
            skipSubscribe: true,
            checkboxEnabled: true
        };

        smoothScrollTo();

        if (!this.props.location.state) {
            this.props.history.push('/');
        }
    }

    onSubmit = async (values) => {
        if (this.state.skipSubscribe) {
            values.firstName = '';
            values.lastName = '';
            values.phoneNumber = '';
            values.emailAddress = '';
            values.residentialAddress = {};
            values.addressLine = '';
            values.suburb = '';
            values.state = '';
            values.postcode = '';
            values.disclaimer = false;
        }
        if (values.personalisedSupport === 'None') {
            values.registerFutureAssistance = true;
        }
        this.setState({ form: { personalDetails: values } });
        await this.saveData();
    };

    validate = (values) => {
        let errors = {};
        let hasError = false;

        if (values.personalisedSupport !== 'Email' && values.personalisedSupport !== 'Phone') {
            hasError = true;
            errors.personalisedSupport = 'Please select an option';
        }

        if (!(values.personalisedSupport === 'None' || values.personalisedSupport === '') ||
            values.subscribeCharity || values.subscribeBySms || values.subscribeByEmail) {
            this.setState({ skipSubscribe: false });

            if (values.disclaimer === false) {
                errors.disclaimer = 'Please read and accept the Terms and Conditions';
                hasError = true;
            }

            if (!values.firstName.trim()) {
                errors.firstName = 'First name is required';
                hasError = true;
            }

            if (!values.lastName.trim()) {
                errors.lastName = 'Last name is required';
                hasError = true;
            }

            if (this.isEmailRequired(values)) {
                if (!this.validateEmail(values.emailAddress.trim())) {
                    errors.emailAddress = 'Please enter a valid email address';
                    hasError = true;
                }
            } else {
                if (values.emailAddress.trim() && !this.validateEmail(values.emailAddress.trim())) {
                    errors.emailAddress = 'Please enter a valid email address';
                    hasError = true;
                }
            }

            if (this.isPhoneRequired(values)) {
                if (!this.validatePhone(values.phoneNumber.trim())) {
                    errors.phoneNumber = 'Please enter a valid phone number';
                    hasError = true;
                }
            } else {
                if (values.phoneNumber.trim() && !this.validatePhone(values.phoneNumber.trim())) {
                    errors.phoneNumber = 'Please enter a valid phone number';
                    hasError = true;
                }
            }

            if (!this.state.manualEntryAddress) {
                if (Object.keys(values.residentialAddress).length === 0 ||
                    (values.residentialAddress && !values.residentialAddress.formattedAddress.trim())) {
                    errors.residentialAddress = 'Residential address is required';
                    hasError = true;
                }
            }

            if (this.state.manualEntryAddress) {
                if (!values.addressLine.trim()) {
                    errors.addressLine = 'Address line is required';
                    hasError = true;
                }

                if (!values.suburb.trim()) {
                    errors.suburb = 'Suburb is required';
                    hasError = true;
                }

                if (!values.state.trim()) {
                    errors.state = 'State is required';
                    hasError = true;
                }

                if (!values.postcode.trim()) {
                    errors.postcode = 'Postcode is required';
                    hasError = true;
                }
            }
        }

        if (!hasError) {
            errors = {};
        }
        return errors;
    };

    validatePhone = (phone) => {
        const re = /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/;
        return re.test(String(phone).toLowerCase());
    };

    validateEmail = (email) => {
        // TODO: get rid of this warning
        // 145:20  warning  Unsafe Regular Expression  security/detect-unsafe-regex
        // eslint-disable-next-line security/detect-unsafe-regex
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    onAddressHasBecomeValid = (updatedAddress, setFieldValue) => {
        setFieldValue('residentialAddress', updatedAddress);
    };

    onAddressHasBecomeInvalid = () => {

    };

    onModeSwitch = (isManualMode) => {
        this.setState({ manualEntryAddress: isManualMode });
    };

    isEmailRequired = (values) => {
        return ((values.personalisedSupport && values.personalisedSupport === 'Email') || values.subscribeByEmail);
    };

    isPhoneRequired = (values) => {
        return ((values.personalisedSupport && values.personalisedSupport === 'Phone') || values.subscribeBySms);
    };

    saveData = async () => {
        const { history, location } = this.props;
        const { form } = this.state;

        try {
            const id = await SurveyFormApi.savePersonalDetails(form.personalDetails, location.state.surveyId);
            if (id === 0) {
                this.setState({ error: '' });
            }
            history.push({
                pathname: `/summary/${id}/`,
                state: {
                    subscribeSuccessful: true,
                    personalisedSupport: form.personalDetails.personalisedSupport
                }
            });
        } catch (error) {
            history.push('/error');
        }
    };

    onNoneSelected = (e, setFieldValue) => {
        if (e.target.checked) {
            this.setState({ checkboxEnabled: false });
            setFieldValue('subscribeByEmail', false);
            setFieldValue('subscribeBySms', false);
            setFieldValue('dontSubscribe', true);
        } else {
            setFieldValue('dontSubscribe', false);
            this.setState({ checkboxEnabled: true });
        }
    };

    render () {
        return (
            <>
                <Header title={'Disaster Assistance Finder'}/>
                <div className="container page">
                    <Formik
                        validate={this.validate}
                        initialValues={
                            {
                                firstName: '',
                                middleName: '',
                                lastName: '',
                                phoneNumber: '',
                                emailAddress: '',
                                residentialAddress: {},
                                addressLine: '',
                                suburb: '',
                                state: '',
                                postcode: '',
                                disclaimer: false,
                                // registerFutureAssistance this is used for Do not contact me checkbox,
                                // if TRUE, customer do not want to be contacted
                                registerFutureAssistance: false,
                                subscribeCharity: false,
                                personalisedSupport: '',
                                receiveUpdates: '',
                                subscribeByEmail: false,
                                subscribeBySms: false,
                                dontSubscribe: false
                            }
                        }
                        onSubmit={this.onSubmit}>
                        {({
                            values,
                            errors,
                            handleChange,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <Form>
                                <div className="page-nav">

                                    {
                                        errors.personalisedSupport &&
                                        <InPageAlert title="Error" variant="error">
                                            Please check the following errors:
                                            <ul className="error-list">
                                                <li> <u>Selecting an option to be contacted via email or call is required</u> </li>
                                            </ul>
                                        </InPageAlert>
                                    }

                                    <button
                                        type="button"
                                        className="button button--link button-back"
                                        onClick={this.props.history.goBack}
                                    >
                                        <img src={ChevronSvg} alt="back" /> Back to results
                                    </button>
                                </div>

                                <h1 style={{ marginTop: '15px' }}>Personalised support</h1>
                                <div className="label-desc">All fields are required, unless marked otherwise</div>

                                <div className="page-sub-section" style={{ paddingBottom: 0 }}>
                                    <h2 style={{ margin: '0 0 10px' }}>Customer Care Program</h2>
                                    <span>Our customer care service provide personalised support for people recovering from the impact of a natural disaster.
                                        <br/> Please select how you would like to be contacted by your Customer
                                        Care representative or <a className="button--link button--external text-underline lighter-text"
                                            href="https://www.service.nsw.gov.au/OnlineBookings"
                                            target="_new">book an appointment.</a></span>
                                    <fieldset className="pad-left-2 form__item form__options" style={{ marginBottom: 0 }}>
                                        <div className="form__radios">
                                            <div className="form__item">
                                                <input value="Email"
                                                    onChange={handleChange}
                                                    type="radio" id="personalisedSupport-email"
                                                    name={'personalisedSupport'}/>
                                                <label className="lighter-text" htmlFor="personalisedSupport-email">Email</label>
                                            </div>
                                            <div className="form__item">
                                                <input value="Phone" onChange={handleChange}
                                                    type="radio" id="personalisedSupport-call"
                                                    name={'personalisedSupport'} />
                                                <label className="lighter-text" htmlFor="personalisedSupport-call">Call</label>
                                            </div>
                                        </div>
                                        <ErrorMessage className="form__error" name={'personalisedSupport'} component={'div'}/>
                                    </fieldset>
                                </div>

                                {/* <div className="page-sub-section">
                                    <h2>Bushfire Recovery Updates</h2>
                                    <span>Subscribe to stay informed of disaster recovery services and information.
                                        <br />Please select your preferred way to receive updates.</span>
                                    <fieldset className="pad-left-2 form__item form__options">
                                        <div className="form__checkbox  input-checkbox">
                                            <div className="form__item">
                                                <input type="checkbox" id="subscribeByEmail" name={'subscribeByEmail'} checked={values.subscribeByEmail}
                                                    disabled={!this.state.checkboxEnabled}
                                                    onChange={(e) => {
                                                        setFieldValue('subscribeByEmail', !!e.target.checked)
                                                    }}
                                                />
                                                <label className="lighter-text" htmlFor="subscribeByEmail">Email</label>
                                            </div>
                                            <div className="form__item">
                                                <input type="checkbox" id="subscribeBySms" name={'subscribeBySms'} checked={values.subscribeBySms}
                                                    disabled={!this.state.checkboxEnabled}
                                                    onChange={(e) => { setFieldValue('subscribeBySms', !!e.target.checked) }}/>
                                                <label className="lighter-text" htmlFor="subscribeBySms">SMS</label>
                                            </div>
                                            <div className="form__item">
                                                <input type="checkbox" id="dontSubscribe" name={'dontSubscribe'} checked={values.dontSubscribe}
                                                    onChange={(e) => {
                                                        this.onNoneSelected(e, setFieldValue)
                                                    }}/>
                                                <label className="lighter-text" htmlFor="dontSubscribe">I don't wish to subscribe to updates</label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>

                                <div className="page-sub-section">
                                    <h2>Charity Assistance Registry</h2>
                                    <span>Service NSW is working closely with other agencies such as the Australian
                                        Red Cross, St Vincent De Paul, Anglicare and the Salvation Army.
                                        Do you consent to your name, suburb and contact details being shared with these charities to see if they can offer further assistance?</span>
                                    <fieldset className="pad-left-2 form__item form__options">
                                        <div className="form__radios">
                                            <div className="form__item">
                                                <input value={true}
                                                    onChange={(e) => { setFieldValue('subscribeCharity', true) }}
                                                    type="radio" id="subscribeCharity-yes"
                                                    name={'subscribeCharity'}/>
                                                <label className="lighter-text" htmlFor="subscribeCharity-yes">Yes</label>
                                            </div>
                                            <div className="form__item">
                                                <input value={false}
                                                    onChange={(e) => { setFieldValue('subscribeCharity', false) }}
                                                    type="radio" id="subscribeCharity-no"
                                                    name={'subscribeCharity'} />
                                                <label className="lighter-text" htmlFor="subscribeCharity-no">No</label>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <ErrorMessage className="form__error" name={'subscribeCharity'} component={'div'}/>
                                </div> */}

                                <div className="page-sub-section">

                                    <h2>Personal details</h2>

                                    <div className="details">
                                        <div className="form__item">
                                            <label htmlFor="firstName">First name</label>
                                            <input type="text" id="firstName" className="form__text" value={values.firstName}
                                                onChange={handleChange}/>
                                            <ErrorMessage className="form__error" name={'firstName'} component={'div'}/>
                                        </div>

                                        <div className="form__item">
                                            <label htmlFor="lastName">Last name</label>
                                            <input type="text" id="lastName" className="form__text" value={values.lastName}
                                                onChange={handleChange}/>
                                            <ErrorMessage className="form__error" name={'lastName'} component={'div'}/>
                                        </div>

                                    </div>

                                    <div className="page-sub-section details">
                                        <h2 style={{ marginTop: '10px' }}>Contact details</h2>

                                        <div className="form__item">
                                            <label htmlFor="phoneNumber">Phone number { this.isEmailRequired(values) && <span>(Optional)</span>}</label>
                                            <input type="tel" id="phoneNumber" className="form__text" value={values.phoneNumber}
                                                onChange={handleChange}/>
                                            <div>Enter your phone number using 10 digits with no spaces or symbols.
                                                <br />Include the area code if you are entering a landline.
                                            </div>
                                            <ErrorMessage className="form__error" name={'phoneNumber'} component={'div'}/>
                                        </div>

                                        <div className="form__item">
                                            <label htmlFor="emailAddress">Email address { this.isPhoneRequired(values) && <span>(Optional)</span>}</label>
                                            <input type="text" id="emailAddress" className="form__text" value={values.emailAddress.trim()}
                                                onChange={(e) => {
                                                    setFieldValue('emailAddress', e.target.value.trim());
                                                }}/>
                                            <div>Enter your email address using the format example@email.com</div>
                                            <ErrorMessage className="form__error" name={'emailAddress'} component={'div'}/>
                                        </div>

                                    </div>

                                    <div>
                                        <h2 style={{ marginTop: 0 }}>Residential Address</h2>
                                        <div className="label-desc">Providing your residential address helps us to better understand impacted areas.</div>

                                        <div>
                                            <AddressInput label='Residential address'
                                                onAddressHasBecomeValid={ (e) => { this.onAddressHasBecomeValid(e, setFieldValue); }}
                                                value={values.residentialAddress}
                                                onAddressHasBecomeInvalid={ (e) => { this.onAddressHasBecomeInvalid(e, setFieldValue); }}
                                                onSwitchToManual={this.onModeSwitch}
                                                onSwitchToAutoSuggest={this.onModeSwitch}
                                                setFieldValue={(k, v) => { setFieldValue(k, v); }}
                                            />
                                            <ErrorMessage className="form__error" name={'residentialAddress'} component={'div'}/>
                                        </div>
                                    </div>

                                    <div className="disclaimer">

                                        <h3 style={{ marginBottom: 0 }}>Declaration</h3>
                                        <fieldset className="form__item form__options" style={{ marginTop: '15px' }}>
                                            <div className="form__checkbox  input-checkbox">
                                                <div className="form__item">
                                                    <input type="checkbox" id="disclaimer" name={'disclaimer'}
                                                        checked={values.disclaimer}
                                                        onChange={(e) => {
                                                            setFieldValue('disclaimer', !!e.target.checked);
                                                        }}
                                                    />
                                                    <label htmlFor="disclaimer">I have read and accepted the <a target="_new"
                                                        className="button--link button--external text-underline"
                                                        href="https://www.service.nsw.gov.au/terms-use">Terms and
                                                        Conditions.</a>
                                                    <div className="lighter-text">Our <a className="button--link button--external text-underline" style={{ fontWeight: 500 }} target="_new"
                                                        href="https://www.service.nsw.gov.au/privacy">privacy
                                                            statement</a> explains how we collect and use information.</div>
                                                    </label>
                                                    <ErrorMessage className="form__error" name={'disclaimer'} component={'div'}/>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <div className="form__actions btn-section" style={{ maxWidth: '100%' }}>
                                    <input type="submit" className={'button button--large button--primary flex-right '}
                                        value="Submit" disabled={isSubmitting}/>
                                    <input type="button" className="button button--large button--secondary flex-left"
                                        value="Cancel" onClick={this.props.history.goBack} />
                                </div>

                            </Form>
                        )}

                    </Formik>
                </div>
            </>
        );
    }
}

PersonalDetailsForm.propTypes = {
    previousStep: PropTypes.func,
    nextStep: PropTypes.func,
    nextAction: PropTypes.func,
    saveData: PropTypes.func,
    isActive: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(PersonalDetailsForm);
