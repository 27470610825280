import React from 'react';
import PropTypes from 'prop-types';
import './WithStyledLegend.css';

function WithStyledLegend({ Component, legendText, ...props }) {
    return (
        <Component
            {...props}
            legend={
                <legend className="fieldset__legend-label">
                    {legendText}
                </legend>
            }
        />
    );
}

WithStyledLegend.propTypes = {
    Component: PropTypes.elementType,
    legendText: PropTypes.string
};

export default WithStyledLegend;
