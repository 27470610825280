import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik, ErrorMessage } from 'formik';
import { withRouter } from 'react-router-dom';
import { CovidSurveyFormApi } from '../CovidSurveyFormApi';
import AddressInput from '../../address-input/AddressInput';
import { Button, SectionHeader, HorizontalRule, Input, FormCheckbox } from '@snsw-gel/react';
import { smoothScrollTo, ScrollToError } from '../../../common';
import './CovidPersonalDetailsForm.scss';
import { validatePhone, validateEmail, onAddressHasBecomeValid } from '../../common/validate';

class CovidPersonalDetailsForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            manualEntryAddress: false,
            form: {},
            skipSubscribe: true,
            checkboxEnabled: true,
            errors: {}
        };

        smoothScrollTo();

        if (!this.props.location.state) {
            this.props.history.push('/');
        }

        this.addressRef = React.createRef();
    }

    onSubmit = async (values) => {
        if (this.state.skipSubscribe) {
            values.firstName = '';
            values.lastName = '';
            values.phoneNumber = '';
            values.emailAddress = '';
            values.residentialAddress = {};
            values.addressLine = '';
            values.suburb = '';
            values.state = '';
            values.postcode = '';
            values.disclaimer = false;
        }
        if (values.personalisedSupport === 'None') {
            values.registerFutureAssistance = true;
        }
        this.setState({ form: { personalDetails: values } });
        await this.saveData();
    };

    validate = (values) => {
        let errors = {};
        let hasError = false;

        this.setState({ skipSubscribe: false });

        if (!values.firstName.trim()) {
            errors.firstName = 'First name is required';
            hasError = true;
        }

        if (!values.lastName.trim()) {
            errors.lastName = 'Last name is required';
            hasError = true;
        }

        if (values.emailAddress.trim() && !validateEmail(values.emailAddress.trim())) {
            errors.emailAddress = 'Please enter a valid email address';
            hasError = true;
        }
        if (values.phoneNumber.trim() && !validatePhone(values.phoneNumber.trim())) {
            errors.phoneNumber = 'Please enter a valid phone number';
            hasError = true;
        }
        if (!values.phoneNumber.trim()) {
            errors.phoneNumber = 'Phone number is required';
            hasError = true;
        }

        if (!this.state.manualEntryAddress) {
            if (Object.keys(values.residentialAddress).length === 0 ||
                    (values.residentialAddress && !values.residentialAddress.formattedAddress.trim())) {
                errors.residentialAddress = 'Residential address is required';
                hasError = true;
            }
        }

        if (this.state.manualEntryAddress) {
            if (!values.addressLine.trim()) {
                errors.addressLine = 'Address line is required';
                hasError = true;
            }

            if (!values.suburb.trim()) {
                errors.suburb = 'Suburb is required';
                hasError = true;
            }

            if (!values.state.trim()) {
                errors.state = 'State is required';
                hasError = true;
            }

            if (!values.postcode.trim()) {
                errors.postcode = 'Postcode is required';
                hasError = true;
            }
        }

        if (values.disclaimer === false) {
            errors.disclaimer = 'Please read and accept the Terms and Conditions';
            hasError = true;
        }

        if (!hasError) {
            errors = {};
        }

        this.setState({ errors });
        return errors;
    };

    onAddressHasBecomeInvalid = () => {
        // onAddressHasBecomeInvalid may be doing nothing but it's still being used/called in AddressInput multiple times at the correct times when address is invalid
    };

    onModeSwitch = (isManualMode) => {
        this.setState({ manualEntryAddress: isManualMode });
    };

    saveData = async () => {
        const { history, location } = this.props;
        const { form } = this.state;

        try {
            const id = await CovidSurveyFormApi.savePersonalDetails(form.personalDetails, location.state.surveyId);
            if (id === 0) {
                this.setState({ error: '' });
            }
            history.push({
                pathname: `/covid/summary/${id}/`,
                state: {
                    subscribeSuccessful: true,
                    personalisedSupport: form.personalDetails.personalisedSupport
                }
            });
        } catch (error) {
            history.push('/error');
        }
    };

    render () {
        return (
            <>
                <SectionHeader title='Request a support callback' label='COVID-19 Assistance Finder' />
                <div className="container page">
                    <Formik
                        validate={this.validate}
                        initialValues={
                            {
                                firstName: '',
                                middleName: '',
                                lastName: '',
                                phoneNumber: '',
                                emailAddress: '',
                                residentialAddress: {},
                                addressLine: '',
                                suburb: '',
                                state: '',
                                postcode: '',
                                disclaimer: false,
                                // registerFutureAssistance this is used for Do not contact me checkbox,
                                // if TRUE, customer do not want to be contacted
                                registerFutureAssistance: false,
                                subscribeCharity: false,
                                personalisedSupport: '',
                                receiveUpdates: '',
                                subscribeByEmail: false,
                                subscribeBySms: false,
                                dontSubscribe: false
                            }
                        }
                        onSubmit={this.onSubmit}>
                        {({
                            values,
                            errors,
                            handleChange,
                            setFieldValue,
                            isSubmitting,
                            touched,
                            submitCount
                        }) => (
                            <Form className="cpd-form">
                                <ScrollToError
                                    submitCount={submitCount}
                                    errors={this.state.errors}
                                    addressRefs={[
                                        { name: 'residentialAddress', ref: this.addressRef }
                                    ]}
                                />

                                <div className='cpd-header-subtitle'>Please complete this form to receive a callback from one of our COVID Savings finder Specialists. You can expect to be contacted within 24 hours.</div>
                                <HorizontalRule marginTop="2rem" marginBottom="2rem" />
                                <span>All fields are mandatory unless marked optional.</span>

                                <div className="page-sub-section">
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        value={values.firstName}
                                        hasError={!!errors.firstName && touched.firstName}
                                        errorMessage={errors.firstName}
                                        onChange={handleChange}
                                    />

                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        value={values.lastName}
                                        hasError={!!errors.lastName && touched.lastName}
                                        errorMessage={errors.lastName}
                                        onChange={handleChange}
                                    />

                                    <Input
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        type="tel"
                                        className="cpd-phone-input"
                                        label="Phone number"
                                        value={values.phoneNumber}
                                        hasError={!!errors.phoneNumber && touched.phoneNumber}
                                        errorMessage={errors.phoneNumber}
                                        onChange={handleChange}
                                    />

                                    <Input
                                        id="emailAddress"
                                        name="emailAddress"
                                        type="email"
                                        label="Email address (optional)"
                                        value={values.emailAddress}
                                        hasError={!!errors.emailAddress && touched.emailAddress}
                                        errorMessage={errors.emailAddress}
                                        onChange={handleChange}
                                    />

                                    <div ref={this.addressRef}>
                                        <AddressInput
                                            label='Enter your address'
                                            onAddressHasBecomeValid={ (e) => { onAddressHasBecomeValid(e, setFieldValue); }}
                                            value={values.residentialAddress}
                                            onAddressHasBecomeInvalid={ (e) => { this.onAddressHasBecomeInvalid(e, setFieldValue); }}
                                            onSwitchToManual={this.onModeSwitch}
                                            onSwitchToAutoSuggest={this.onModeSwitch}
                                            setFieldValue={(k, v) => { setFieldValue(k, v); }}
                                            isCovid
                                        />
                                        <ErrorMessage className="form__error" name={'residentialAddress'} component={'div'} />
                                    </div>

                                    <h3 className="cpd-declaration-heading">Declaration</h3>
                                    <span htmlFor="disclaimer">
                                            Our{' '}
                                        <a
                                            target="_new"
                                            className="button--link text-underline"
                                            href="https://www.service.nsw.gov.au/covid-appointment-privacy"
                                        >
                                                Privacy Statement
                                        </a>
                                        {' '}explains how Service NSW collects and uses personal information.
                                    </span>
                                    <FormCheckbox
                                        id="disclaimer"
                                        name="disclaimer"
                                        label="I accept the Privacy Statement."
                                        value="disclaimer"
                                        onChange={() => {
                                            setFieldValue('disclaimer', !values.disclaimer);
                                        }}
                                        checked={values.disclaimer}
                                        hasError={!!errors.disclaimer && touched.disclaimer}
                                        errorMessage={errors.disclaimer}
                                    />
                                </div>

                                <div className="form__actions btn-section">
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="flex-right"
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        theme="secondary"
                                        className="flex-left"
                                        onClick={this.props.history.goBack}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </>
        );
    }
}

CovidPersonalDetailsForm.propTypes = {
    previousStep: PropTypes.func,
    nextStep: PropTypes.func,
    nextAction: PropTypes.func,
    saveData: PropTypes.func,
    isActive: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(CovidPersonalDetailsForm);
