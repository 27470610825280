import 'element-remove';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/array/iterator';
import 'core-js/features/number/is-nan';
import 'core-js/features/object/entries';
import 'core-js/features/string/includes';
import React from 'react';
import { render } from 'react-dom';
import App from './components/app/App';
import './index.scss';
import { gel3Themes, GlobalStyle, theme } from '@snsw-gel/react';
import KiamaGlobalFont from './components/styled/KiamaGlobalFont';
import { ThemeProvider } from 'styled-components';
import { GlobalNav, setGlobalNavUri } from '@snsw/react-global-nav';

setGlobalNavUri(
    process.env.REACT_APP_GLOBAL_NAV_HOST,
    { 'x-api-key': process.env.REACT_APP_GLOBAL_NAV_API_KEY }
);

render(
    <>
        <ThemeProvider theme={ theme }>
            <GlobalNav>
                <GlobalStyle themes={gel3Themes}/>
                <KiamaGlobalFont />
                <App />
            </GlobalNav>
        </ThemeProvider>
    </>
    , document.getElementById('root'));
