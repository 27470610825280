import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import startCase from 'lodash.startcase';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import FooterNav from '../../FooterNav';
import initStepQnA from '../InitialValues/stepQnA';
import './_disaster-type-form.scss';
import WithStyledLegend from '../../styled/WithStyledLegend';
import { FormRadioGroup } from '@snsw-gel/react';

export default class DisasterTypeForm extends Component {
    static displayName = 'DisasterTypeForm';

    static propTypes = {
        previousAction: PropTypes.func.isRequired,
        nextAction: PropTypes.func.isRequired,
        nextStep: PropTypes.func,
        isActive: PropTypes.bool,
        currentStep: PropTypes.number
    };

    static defaultProps = {
        nextStep: Function.prototype,
        isActive: false,
        currentStep: 1
    };

    shouldComponentUpdate (nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState) || !isEqual(this.context, nextContext);
    }

    onValidate = values => {
        const { disasterType } = values;
        const errors = {};

        if (!disasterType) {
            errors.disasterType = 'Please select an option';
        }

        return errors;
    };

    onSubmit = values => {
        const { currentStep, nextAction, nextStep } = this.props;
        const { disasterType } = values;
        const stepQnA = initStepQnA[currentStep - 1];
        const disasterTypeLabel = (disasterType === 'flood' ? 'Flood / Storm' : startCase(disasterType));
        stepQnA.questions[0].answers = [disasterTypeLabel];
        nextAction('disasterType', disasterType, currentStep, stepQnA);
        nextStep();
    };

    renderFormContent ({ values, errors, touched, handleChange, setFieldValue }) {
        return (
            <Form className={classNames('form form-disaster-type', { hidden: !this.props.isActive })}>
                <div className="form-title">Your situation</div>
                <div className="form-help">All fields are required</div>

                <WithStyledLegend
                    Component={FormRadioGroup}
                    id="disasterType"
                    name="disasterType"
                    legendText="What do you need assistance for?"
                    onChange={value => {
                        setFieldValue('disasterType', value);
                        handleChange(value);
                    }}
                    hasError={!!errors.disasterType && !!touched.disasterType}
                    errorMessage={errors.disasterType}
                    value={values.disasterType || ''}
                    options={[
                        {
                            label: 'Bushfire',
                            value: 'bushfire'
                        },
                        {
                            label: 'Flood / Storm',
                            value: 'flood'
                        }
                    ]}
                    vertical
                />

                <FooterNav backLabel="Cancel" onBackPressed={this.props.previousAction} />
            </Form>
        );
    }

    render () {
        return (
            <Formik
                enableReinitialize
                initialValues={{ disasterType: null }}
                onSubmit={this.onSubmit}
                validate={this.onValidate}
            >
                {formik => this.renderFormContent(formik)}
            </Formik>
        );
    }
}
