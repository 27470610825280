import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Grid from '@material-ui/core/Grid';
import { ComponentLoader, theme, HorizontalRule } from '@snsw-gel/react';
import { CovidSurveyFormApi } from '../CovidSurveyFormApi';
import ThumbsUpThumbsDown from '../../thumbs-up-thumbs-down/ThumbsUpThumbsDown';
import CovidDetails from './CovidDetails';
import CovidCategory from '../../common/category/Category';
import Header from '../../header/Header';
import Notice from '../../Notice';
import ShareSummaryResults from './ShareSummaryResults';
import Cards from '../../cards/Cards';
import ShareSummaryResultsWithoutRecaptcha from './ShareSummaryResultsWithoutRecaptcha';
import LocationBlack from './LocationBlack';
import SuburbSuggest from '../../SuburbSuggestor/SuburbSuggest';
import QuestionAnswers from './QuestionAnswers/QuestionAnswers';
import { CacheStore, smoothScrollTo } from '../../../common';
import tile3image from '../../../assets/icons/CAF-business.svg';
import tile1image from '../../../assets/icons/CAF-makeappt.svg';
import tile2image from '../../../assets/icons/CAF-mobile.svg';
// TODO: Why are we importing this sass file? This needs to be changed.
import '../../survey-form/service-categories/ServiceCategories.scss';
import './SupportSummary.scss';
import get from 'lodash.get';

const recaptchaV3ClientKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_CLIENT_KEY;
const supportRecaptcha = process.env.REACT_APP_SUPPORT_RECAPTCHA;

const MESSAGES = {
    subscribedSuccessfully: 'You are subscribed to newly added support notifications.',
    sentEmail: 'Your results link was successfully emailed to'
};

export const buildServiceLookUrl = (searchQuery, postCode) => {
    const baseUrl = process.env.REACT_APP_SERVICE_LOOKUP_URL;
    const postCodeUrlParam = `%20${postCode || process.env.REACT_APP_SERVICE_LOOKUP_DEFAULT_POSTCODE}`;
    return `${baseUrl}${searchQuery || String.empty()}${postCodeUrlParam}`;
};

buildServiceLookUrl.propTypes = {
    searchQuery: PropTypes.string,
    postCode: PropTypes.string
};

class SupportSummary extends Component {
    constructor (props) {
        super(props);

        this.state = {
            summary: [],
            id: '',
            email: '',
            disasterType: 'covid',
            customerId: null,
            totalNewServices: 0,
            emailSent: false,
            recaptchaToken: '',
            showRecaptchaV2: false,
            showChangeBox: false,
            errorOccurred: false, // error in posting
            autoSuggestErrorOccurred: false, // error in autosuggestion
            enterManually: false,
            updatedSuburb: '',
            updatedPostcode: '',
            suburb: '',
            postCode: 0,
            expandedServiceCategoryNames: [],
            notificationText: '',
            salesforceStatus: null
        };
    }

    componentDidMount = async () => {
        smoothScrollTo();
        const { id, email, disasterType } = this.props.match.params;
        const { location } = this.props;

        // Since this is very covid specific component, then we just check it here
        if (disasterType !== 'covid') {
            this.props.history.push('/covid-error');
        }

        const subscribeSuccessful = get(location, 'state.subscribeSuccessful', null);

        const unsubscribeSuccessful = window.sessionStorage.getItem('unsubscribe');
        if (unsubscribeSuccessful) {
            this.setState({
                genericTextMessage: <>You have successfully unsubscribed from COVID-19 Assistance updates. You
                    can <button onClick={this.resubscribeClickHandler} className='resubscribe-button'>resubscribe
                        again</button>.</>,
                noticeMessageType: 'success'
            });
        }

        try {
            const decodedEmail = email ? atob(email) : '';
            const result = await CovidSurveyFormApi.getServices(id, decodedEmail);
            this.setState({
                summary: result.availableServices,
                id: id,
                email: decodedEmail,
                unsubscribeSuccessful: unsubscribeSuccessful,
                subscribeSuccessful,
                disasterType: disasterType,
                subscribedForUpdates: result.subscribedForUpdates,
                totalNewServices: result.totalNewServices,
                customerId: result.customerId,
                emailSent: result.emailSent,
                selectedCategories: result.selectedCategories,
                postCode: result.postCode,
                suburb: result.suburb,
                userAnswers: result.userAnswers,
                salesforceStatus: result.salesforceStatus
            },
            () => {
                window.sessionStorage.removeItem('unsubscribe');
            });

            try {
                const toCache = {
                    id: id,
                    disasterType: disasterType,
                    optionIDs: result.userAnswers
                };

                CacheStore.setCache(toCache);
                this.setState({ cachingError: false });
            } catch (e) {
                this.setState({ cachingError: true });
            }

            this.checkUserSubscription();
        } catch (e) {
            this.props.history.push('/covid-error');
        }
    };

    checkUserSubscription () {
        const subscribedFormId = window.sessionStorage.getItem('subscribedFormId');

        if (!subscribedFormId) {
            return;
        }
        this.setState({ notificationText: MESSAGES.subscribedSuccessfully }, () => window.sessionStorage.removeItem('subscribedFormId'));
    }

    resubscribeClickHandler = async (values) => {
        try {
            const { disasterType, id } = this.props.match.params;
            this.setState({
                loading: true
            });
            const result = await CovidSurveyFormApi.resubscribe(disasterType, id);
            if (result === 200) {
                setTimeout(() => {
                    this.setState({
                        resubscribeSuccessful: true,
                        loading: false,
                        unsubscribeSuccessful: false,
                        genericTextMessage: <>You have successfully subscribed to COVID-19 Assistance updates.</>,
                        noticeMessageType: 'success'
                    });
                }, 1500);
            } else {
                this.setState({
                    resubscribeSuccessful: false,
                    loading: false
                });
            }
        } catch (e) {
            this.props.history.push('/covid-error');
        }
    };

    getSentEmailNotificationText = (emailAddress) => <>{MESSAGES.sentEmail} <b>{emailAddress}</b></>;

    recaptchaV3SendShareSummaryResults = async (values) => {
        try {
            const verified = await CovidSurveyFormApi.verifyGoogleRecaptchaV3(values.token, 'shareSummaryResults');
            if (verified) {
                const result = await CovidSurveyFormApi.sendSummarySurveyFormEmail(this.state.id, values.emailAddress, values.token, this.state.action);
                if (result === 200) {
                    const sentEmailNotificationText = this.getSentEmailNotificationText(values.emailAddress);
                    this.setState({ emailSent: true, notificationText: sentEmailNotificationText, email: values.emailAddress });
                }
            } else {
                this.setState({
                    emailAddress: values.emailAddress,
                    showRecaptchaV2: true
                });
            }
        } catch (e) {
            this.props.history.push('/covid-error');
        }
    };

    recaptchaV2SendShareSummaryResults = async (recaptchaValue) => {
        const verified = await CovidSurveyFormApi.verifyGoogleRecaptchaV2(recaptchaValue, 'shareSummaryResults');

        if (verified) {
            const result = await CovidSurveyFormApi.sendSummarySurveyFormEmail(this.state.id, this.state.emailAddress, this.state.recaptchaToken, this.state.action);
            if (result === 200) {
                const sentEmailNotificationText = this.getSentEmailNotificationText(this.state.emailAddress);
                this.setState({
                    emailSent: true,
                    notificationText: sentEmailNotificationText,
                    email: this.state.emailAddress,
                    showRecaptchaV2: false
                });
            }
        }
    };

    shareResultsHandler = async (values) => {
        try {
            const result = await CovidSurveyFormApi.sendSummarySurveyFormEmail(this.state.id, values.emailAddress, values.token, this.state.action);
            if (result === 200) {
                smoothScrollTo();
                const sentEmailNotificationText = this.getSentEmailNotificationText(values.emailAddress);
                this.setState({ emailSent: true, notificationText: sentEmailNotificationText, email: values.emailAddress });
            }
        } catch (e) {
            this.props.history.push('/covid-error');
        }
    };

    showChangeBox = () => this.setState({ showChangeBox: true });

    hideChangeBox = () => this.setState({ showChangeBox: false, errorOccurred: false, autoSuggestErrorOccurred: false, invalidPostcode: false, enterManually: false });

    toggleManualAutoSearch = () => this.setState({ enterManually: !this.state.enterManually, autoSuggestErrorOccurred: false, updatedPostcode: '', updatedSuburb: '', invalidPostcode: false, errorOccurred: false });

    getChangePostcodeButton = (text, linkText = 'Change') => {
        return (
            <div>
                {text}
                <button className="link-button" onClick={this.showChangeBox} style={{ display: 'inline' }}> {linkText}</button>
            </div>
        );
    };

    validatePostCode = (postCode) => {
        const re = /2[0-9]{3}$/;
        return re.test(postCode);
    };

    handleManualPostcodeChange = () => {
        const { updatedPostcode } = this.state;
        if (updatedPostcode === '' || (updatedPostcode !== '' && (updatedPostcode.charAt(0) !== '2' || !this.validatePostCode(updatedPostcode)))) {
            // Error
            this.setState({ invalidPostcode: true });
            return;
        }
        this.handleLocationChange();
    };

    handleLocationChange = async () => {
        const { id, updatedSuburb, updatedPostcode, enterManually, userAnswers } = this.state;

        if (enterManually && (updatedPostcode === '' || updatedSuburb !== '')) {
            this.setState({ invalidPostcode: true });
            return;
        } else if (!enterManually && (updatedSuburb === '' || updatedPostcode === '')) {
            // Should never reach here!
            return;
        }

        try {
            const result = await CovidSurveyFormApi.updateSuburbLocation(id, { suburb: updatedSuburb, postcode: updatedPostcode });

            if (!result) {
                this.setState({
                    errorOccurred: true,
                    updatedSuburb: '',
                    updatedPostcode: ''
                });
                return;
            }

            const filteredUserAnswers = [...userAnswers.filter(answer => !answer.includes('suburb') && !answer.includes('postCode')), `postCode${result.postcode}`, `suburb${result.suburb}`];

            CacheStore.updateLocation(`suburb${result.suburb}`, `postCode${result.postcode}`);

            this.setState({
                postCode: result.postcode,
                suburb: result.suburb,
                updatedSuburb: '',
                updatedPostcode: '',
                showChangeBox: false,
                enterManually: false,
                userAnswers: filteredUserAnswers
            });
        } catch (error) {
            this.props.history.push('/covid-error');
        }
    };

    onSelectCallBackHandler = selected => this.setState({ updatedSuburb: selected.name, updatedPostcode: selected.postcode }, () => this.handleLocationChange(selected));
    onChangeCallBackHandler = value => this.setState({ updatedSuburb: '', updatedPostcode: '', errorOccurred: false, autoSuggestErrorOccurred: false });
    onErrorCallBackHandler = () => this.setState({ autoSuggestErrorOccurred: true });
    showCancelButton = () => this.state.postCode || this.state.suburb;
    handlePostcodeChange = (event) => this.setState({ updatedPostcode: event.target.value, invalidPostcode: false });

    getAutosuggestBox = () => {
        const { postCode, suburb, showChangeBox, autoSuggestErrorOccurred, errorOccurred } = this.state;

        return (
            <>
                {(showChangeBox || (postCode === 0 && suburb === null))
                    ? <Fragment>
                        <div className="autosuggest-button-container-1 summary-container" >
                            <div className="flex-spacing item normal-text">
                                <SuburbSuggest
                                    onSelectCallBack={this.onSelectCallBackHandler}
                                    onChangeCallBack={this.onChangeCallBackHandler}
                                    onErrorCallBack={this.onErrorCallBackHandler}
                                />
                            </div>

                            {this.showCancelButton()
                                ? <div>
                                    <button className="link-button"
                                        onClick={this.hideChangeBox}>Cancel
                                    </button>
                                </div>
                                : null
                            }
                        </div>
                        {
                            autoSuggestErrorOccurred
                                ? <Notice type="error" title="Something's not right"
                                    message="It seems we’re facing some technical issues, please enter your postcode manually."/>
                                : null
                        }
                        {
                            errorOccurred
                                ? <Notice type="error" title="Something's not right"
                                    message="It seems we’re facing some technical issues, please try again."/>
                                : null
                        }
                    </Fragment>
                    : null
                }
            </>
        );
    };

    getLocationChangeBox = () => {
        const { postCode, suburb, showChangeBox, enterManually, invalidPostcode, errorOccurred, updatedPostcode } = this.state;

        return (
            <>
                {
                    (showChangeBox || (postCode === 0 && suburb === null))
                        ? <div className="suggestions-master-container notranslate" style={{ paddingTop: '15px' }}>

                            <div className="form__item label-button-container" >
                                <label>{enterManually ? 'Enter your NSW postcode' : 'Enter your NSW postcode or suburb'}</label>
                                <input id="toggle-button" type="button" className="button  button--link text-link lighter-text right-shift notranslate" value={enterManually ? 'Back to search' : 'Enter postcode manually'} onClick={this.toggleManualAutoSearch}/>
                            </div>

                            {
                                enterManually
                                    ? <div className="autosuggest-button-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', flexWrap: 'wrap' }}>
                                        <div id="box-error" className="autosuggest-button-container-item" style={{ flexGrow: '1' }}>
                                            <input id="postcode-box" style={{ flexGrow: '1' }} name='postcode' onChange={this.handlePostcodeChange} value={updatedPostcode} maxLength={4} placeholder="enter your postcode here" />
                                            {
                                                invalidPostcode
                                                    ? <div className="form__error">Enter a valid NSW postcode </div>
                                                    : null
                                            }

                                        </div>
                                        <div className="autosuggest-button-container-item">
                                            <button id="summary-manual-submit" className="button button--primary " onClick={this.handleManualPostcodeChange} >Submit</button>
                                        </div>

                                        {this.showCancelButton()
                                            ? <div className="autosuggest-button-container-item" >
                                                <button className="link-button" onClick={this.hideChangeBox}>Cancel</button>
                                            </div>
                                            : null
                                        }

                                        {
                                            errorOccurred
                                                ? <Notice type="error" title="Something's not right" message="It seems we’re facing some technical issues, please can try again."/>
                                                : null
                                        }

                                    </div>

                                    : this.getAutosuggestBox()

                            }

                        </div>
                        : null
                }

            </>
        );
    };

    onCollapsibleOpening = (category) => () => {
        const { expandedServiceCategoryNames } = this.state;

        if (!expandedServiceCategoryNames.includes(category.category)) {
            this.setState({
                expandedServiceCategoryNames: [
                    ...expandedServiceCategoryNames,
                    category.category
                ]
            });
        }
    };

    onCollapsibleClosed = (category) => () => {
        const { expandedServiceCategoryNames } = this.state;

        if (expandedServiceCategoryNames.includes(category.category)) {
            this.setState({
                expandedServiceCategoryNames: expandedServiceCategoryNames.filter(name => name !== category.category)
            });
        }
    };

    renderSubNotification () {
        const { notificationText, unsubscribeSuccessful, resubscribeSuccessful, genericTextMessage, noticeMessageType } = this.state;
        if (unsubscribeSuccessful || resubscribeSuccessful) {
            return <Notice
                message={genericTextMessage}
                type={noticeMessageType} />;
        }
        return notificationText &&
            <Notice
                message={notificationText}
                type='success'/>;
    }

    SaveYourResultDisplay() {
        if (this.state.emailSent || this.state.customerId != null) {
            return (<>
                <div>
                    {this.renderSubNotification()}
                </div></>);
        }
        return <></>;
    }

    render () {
        const {
            id,
            summary,
            unsubscribeSuccessful,
            loading,
            resubscribeSuccessful,
            customerId,
            subscribedForUpdates,
            emailSent,
            selectedCategories,
            postCode,
            suburb,
            expandedServiceCategoryNames,
            userAnswers,
            cachingError,
            subscribeSuccessful,
            salesforceStatus
        } = this.state;

        const url = buildServiceLookUrl('covid%20test%20clinic', postCode);

        return (
            <Fragment>
                <ThemeProvider theme={theme}>
                    <Header title={'COVID-19 Assistance Finder'}/>
                    <div className="container">
                        <section className="results-page">
                            {subscribeSuccessful && (
                                <Notice
                                    message='You are subscribed to newly added support notifications.'
                                    type="success"
                                    style={{ margin: '1.5rem 0 3.5rem' }}
                                />
                            )}
                            { this.SaveYourResultDisplay()}
                            {cachingError && <Notice type="error" title="Error" message="We could not return to the provided answer, please try again or try your browser back button."/>}
                            <div className="grid__container">
                                <div className="grid__8-col">
                                    <div className="summary-header">
                                        <div className="summary-header__ref-no">
                                            <p className="ref-color">
                                                Reference number: {id}
                                            </p>
                                        </div>
                                        <h1>Your results</h1>
                                        Select the category headings below to see the assistance options you may be eligible for. If you would like personalised assistance or to save these results for later see below.
                                    </div>
                                    <ul className="product-categories">
                                        {summary
                                            .sort((service1, service2) => service1.rank - service2.rank)
                                            .filter(service => selectedCategories && selectedCategories.includes(service.category))
                                            .map(category => {
                                                return (
                                                    <li key={`service-item-selected-${category.category}`} className="category-container">
                                                        {category.services
                                                            ? <Collapsible
                                                                trigger={<CovidCategory category={category} />}
                                                                tabIndex={0}
                                                                onOpening={this.onCollapsibleOpening(category)}
                                                                onClose={this.onCollapsibleClosed(category)}
                                                            >
                                                                <CovidDetails
                                                                    services={category.services}
                                                                    category={category}
                                                                    postcode={postCode}
                                                                    categoryName={category.category}
                                                                    expandedServiceCategoryNames={expandedServiceCategoryNames}
                                                                />
                                                            </Collapsible>
                                                            : <CovidCategory category={category} />
                                                        }
                                                    </li>
                                                );
                                            })
                                        }

                                        {summary
                                            .sort((service1, service2) => service1.rank - service2.rank)
                                            .filter(service => selectedCategories && !selectedCategories.includes(service.category))
                                            .map(category => {
                                                return (
                                                    <li key={`service-item-${category.category}`} className="category-container">
                                                        {category.services
                                                            ? <Collapsible
                                                                trigger={<CovidCategory category={category} />}
                                                                tabIndex={0}
                                                                onOpening={this.onCollapsibleOpening(category)}
                                                                onClose={this.onCollapsibleClosed(category)}
                                                            >
                                                                <CovidDetails
                                                                    services={category.services}
                                                                    postcode={postCode}
                                                                    categoryName={category.category}
                                                                    expandedServiceCategoryNames={expandedServiceCategoryNames}
                                                                />
                                                            </Collapsible>
                                                            : <CovidCategory category={category} />
                                                        }
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                    {!emailSent && supportRecaptcha === 'true' &&
                                    <GoogleReCaptchaProvider reCaptchaKey={recaptchaV3ClientKey} useEnterprise={true}>
                                        <ShareSummaryResults
                                            recaptchaV3SendShareSummaryResults={this.recaptchaV3SendShareSummaryResults.bind(this)}
                                            recaptchaV2SendShareSummaryResults={this.recaptchaV2SendShareSummaryResults.bind(this)}
                                            showRecaptchaV2={this.state.showRecaptchaV2}/>
                                    </GoogleReCaptchaProvider>
                                    }
                                    {(loading) &&
                                    <div className="loader-div">
                                        <ComponentLoader
                                            active
                                            fullPage
                                            label={"One moment please... \n We're processing your resubscription."}
                                        /></div>
                                    }

                                    {customerId && !subscribedForUpdates && !resubscribeSuccessful && !unsubscribeSuccessful &&
                                    <div className="covid-summary-signup">
                                        <h3>Stay up-to-date on COVID-19 assistance.</h3>
                                        <h4 style={{ fontWeight: 'normal' }}>We'll email you updates when new COVID-19
                                            assistance has been added.</h4>
                                        <button
                                            className={'button button--large button--primary flex-left '}
                                            onClick={this.resubscribeClickHandler}
                                        >
                                            Receive updates
                                        </button>

                                    </div>}
                                    {!emailSent && supportRecaptcha === 'false' &&
                                    <ShareSummaryResultsWithoutRecaptcha
                                        shareResultsHandler={this.shareResultsHandler.bind(this)}/>
                                    }
                                    <hr className="spacer"/>
                                    <div className="line-spacing" ></div>
                                    {(salesforceStatus === null) &&
                                    <div className="general-information">
                                        <h2 className="general-information__title">Personalised assistance</h2>

                                        <div className="general-information__content">
                                            <Cards
                                                title='Make an appointment'
                                                image={tile1image}
                                                url="https://www.service.nsw.gov.au/OnlineBookings"
                                                arrowText="Savings finder appointment "
                                            >
                                                <span>For <b>non-urgent</b> COVID assistance, please make an appointment. Select <b>Savings finder</b> as your appointment type.</span>
                                            </Cards>
                                            <Cards
                                                title="Request a callback"
                                                image={tile2image}
                                                onClick={() => this.props.history.push({ pathname: '/covid/subscribe', state: { surveyId: this.state.id, isValid: true, prevPath: location.pathname } })}
                                                arrowText="Request a callback "
                                            >
                                                <span>If you need <b>urgent</b> COVID-19 support and would like to speak to a specialist immediately, please request a callback.</span>
                                            </Cards>
                                            <Cards
                                                title="Business callback"
                                                image={tile3image}
                                                url="https://mybusiness.service.nsw.gov.au/concierge"
                                                arrowText="Business callback "
                                            >
                                                <span>If you are a business or sole trader looking for assistance, please request a business callback.</span>
                                            </Cards>
                                        </div>
                                        <div style={{ marginTop: '2rem' }}>If you are unable to access these services you can call the general assistance line on 13 77 88, Monday to Friday, 7am - 7pm AEST.</div>
                                        <HorizontalRule marginTop="3rem" marginBottom="3rem"/>
                                    </div>
                                    }
                                    <div> <h2 className="general-information__title">Other general services</h2></div>
                                    <ul className="product-categories">
                                        <div style={{
                                            border: '2px solid #DEE3E5',
                                            borderRadius: '0.3em'
                                        }}>
                                            <div className="services-box notranslate">
                                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ height: '31px', paddingRight: '10px' }}>
                                                            <LocationBlack/>
                                                        </div>
                                                        {
                                                            (Boolean(postCode) && Boolean(suburb))
                                                                ? this.getChangePostcodeButton(`Services near ${suburb}`)
                                                                : (postCode > 999)
                                                                    ? this.getChangePostcodeButton(`Services near postcode ${postCode}`)
                                                                    : 'Services in your area'
                                                        }
                                                    </div>
                                                    {
                                                        this.getLocationChangeBox()
                                                    }
                                                </div>

                                            </div>

                                            <div className="services-text">
                                                <Grid container spacing={3}>
                                                    <Grid item xs={10} sm={7} style={{ display: 'flex' }}>
                                                        <a
                                                            className="services-text-url"
                                                            href="https://www.nsw.gov.au/covid-19/find-facts-about-covid-19#map-of-nsw-covid-19-cases-and-testing-by-postcode"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            COVID-19 cases near you
                                                        </a>
                                                        <br/>
                                                    </Grid>
                                                    <Grid item xs={10} sm={7} style={{ display: 'flex' }}>
                                                        <a
                                                            className="services-text-url"
                                                            href={url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Local COVID-19 test centres
                                                        </a>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </ul>

                                    <div className="other-assistance-finders">
                                        <div>
                                            <div>
                                                <h3>Savings Finder</h3>
                                                <p>
                                                    <a id="savings-finder-link" href="https://assistance.service.nsw.gov.au/savings-finder"
                                                        target="_blank" rel="noreferrer">The Savings Finder</a> helps you find relevant savings and rebates.
                                                </p>
                                                <h3>Disaster Assistance Finder</h3>
                                                <p>
                                                    If you have been affected by bushfire or flood, the&nbsp;
                                                    <a id="disaster-assistance-link" href="https://disasterassistance.service.nsw.gov.au/"
                                                        target="_blank" rel="noreferrer">Disaster Assistance Finder</a> helps you find available services and programs.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line-spacing" />

                                </div>

                                <div className='feedbackHolder'>
                                    <ThumbsUpThumbsDown sourceApplication={'COVID Assistance Finder'} label={'<h1>Feedback</h1>'}/>
                                </div>

                                {!cachingError && <QuestionAnswers answers={userAnswers} editAnswers={salesforceStatus !== null}/>}
                            </div>
                        </section>
                    </div>
                    <div className="qualtrics-sections">
                        <div className="container sub-container">
                            <div className="qualtrics-sections__feedback">
                                <div className="feedback-title">How did we go with the Covid-19 Assistance Finder?</div>
                                <div className="feedback-text">Tell us what went well, what went wrong and how we can improve.</div>
                                <div><a
                                    className="button button--large button--primary flex-left button--white"
                                    href="https://nswbushfire.syd1.qualtrics.com/jfe/form/SV_6nkIhx363jRwggl"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Give Feedback
                                </a></div>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>
            </Fragment>
        );
    }
}

SupportSummary.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(SupportSummary);
