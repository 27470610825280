import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import memoizeOne from 'memoize-one';
import { Card } from '@snsw-gel/react';
import { ReactComponent as ArrowRightIcon } from '../../assets/arrow-right.svg';
import './Cards.scss';

export default class Cards extends Component {
    static displayName = 'Cards';

    static propTypes = {
        image: PropTypes.string.isRequired,
        url: PropTypes.string,
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        children: PropTypes.any,
        arrowText: PropTypes.any

    };

    static defaultProps = {
        children: null,
        arrowText: null,
        url: '',
        onClick: null
    };

    clickHandler = memoizeOne(() => {
        const { url } = this.props;

        window.open(url);
    });

    shouldComponentUpdate (nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    render () {
        const { children, image, title, onClick, arrowText } = this.props;

        return (
            <div
                className="cards"
                tabIndex="0"
                onClick={onClick || this.clickHandler}
                role="button"
                title={title}
            >

                <div className="cards-image">
                    <img alt={title} className="cards-image__image" src={image} />
                </div>

                <div className="cards-title">
                    <Card
                        className="cards-title__card"
                        height="100%"
                        loading={false}
                        vertical={false}
                        title={title}

                    >
                        {children}
                    </Card>
                </div>

                <div className="cards-arrow">
                    <span className="cards-arrow__text"> {arrowText}</span>
                    <ArrowRightIcon />
                </div>
            </div>
        );
    }
}
