import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { theme } from '@snsw-gel/react';
import { ThemeProvider } from 'styled-components';
import OneQuestionAnswer from '../OneQuestionAnswer/OneQuestionAnswer';
import { allSteps, populateAnswers, getAllQuestions } from './QuestionAnswerMapper';
import './QuestionAnswers.scss';
import Accordion from '../Accordion/Accordion';
import AccordionTile from '../Accordion/AccordionTile';

const QuestionAnswers = ({ answers, editAnswers }) => {
    const memoisedQuestionAnswers = useMemo(() => {
        const questionsList = getAllQuestions();
        answers.forEach(ans => populateAnswers(ans, questionsList));

        // removing conditional questions if user selected No
        const conditionalQuestion1Answers = questionsList[7].selectedOptionIds;
        const conditionalQuestion2Answers = questionsList[9].selectedOptionIds;
        const conditionalQuestionBusinessAnswers = questionsList[11].selectedOptionIds;
        const locationAnswers = questionsList[17].selectedOptions;

        if (conditionalQuestion1Answers.includes('lookingForWorkConditionalfalse') || conditionalQuestion1Answers.length === 0) {
            questionsList[8].visible = false;
        }

        if (conditionalQuestion2Answers.includes('furtherStudyConditionalfalse') || conditionalQuestion2Answers.length === 0) {
            questionsList[10].visible = false;
        }

        if (conditionalQuestionBusinessAnswers.includes('notBusinessOwner') || conditionalQuestionBusinessAnswers.length === 0) {
            questionsList[12].visible = false;
            questionsList[13].visible = false;
            questionsList[14].visible = false;
        }

        if (locationAnswers.length === 2) {
            questionsList[17].selectedOptions = [locationAnswers[1] + ' ' + locationAnswers[0]];
        }

        return questionsList.filter(i => i.visible);
    }, [answers]);

    return (
        <ThemeProvider theme={theme}>
            <div className="user-answers-container">
                <Accordion >
                    <h2 style={{ marginTop: '50px' }}>Your answers</h2>
                    {
                        Object.entries(allSteps).map(([key, value]) => {
                            const intNumber = Number(key);
                            return (
                                <AccordionTile step={intNumber} title={value} key={value} >
                                    {
                                        memoisedQuestionAnswers.filter(question => question.step === intNumber).map(questionAnswer => (questionAnswer.id !== 14 && <OneQuestionAnswer key ={questionAnswer.id} step={questionAnswer.step} question={questionAnswer.question} selectedOptions={questionAnswer.selectedOptions} editAnswers={editAnswers}/>))
                                    }
                                </AccordionTile>
                            );
                        })
                    }
                </Accordion>
            </div>
        </ThemeProvider>
    );
};

QuestionAnswers.defaultProps = {
    answers: [],
    editAnswers: false
};

QuestionAnswers.propTypes = {
    answers: PropTypes.arrayOf(String),
    editAnswers: PropTypes.bool
};

export default memo(QuestionAnswers);
