import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import ExclusiveCheckboxGroup from '../../ExclusiveCheckboxGroup';
import FooterNav from '../../FooterNav';
import initStepQnA from '../InitialValues/stepQnA';
import { collectAnswersFromOptions } from '../../../common';
import './BasicAssistanceForm.scss';
import { FormRadioGroup } from '@snsw-gel/react';
import WithStyledLegend from '../../styled/WithStyledLegend';

const impactOptions = [
    {
        label: 'As a business owner',
        value: 'businessOwner'
    },
    {
        label: 'As a farmer or primary producer',
        value: 'farmerOrPrimaryProducer'
    },
    {
        label: 'As a property owner',
        value: 'propertyOwner'
    },
    {
        label: 'As a renter',
        value: 'renter'
    },
    {
        label: 'As a Volunteer Firefighter/SES',
        value: 'volunteerFirefighterOrSES'
    },
    {
        label: 'None of the above',
        value: 'noneOfTheAbove',
        exclusive: true
    }
];

const aboriginalTorresOptions = [
    {
        label: 'Yes',
        value: 'Yes'
    },
    {
        label: 'No',
        value: 'No'
    },
    {
        label: 'Prefer not to say',
        value: 'Prefer not to say',
        exclusive: true
    }
];

const initialValues = {
    businessOwner: false,
    farmerOrPrimaryProducer: false,
    propertyOwner: false,
    renter: false,
    volunteerFirefighterOrSES: false,
    noneOfTheAbove: false
};

export default class BasicAssistanceForm extends Component {
    static displayName = 'BasicAssistanceForm';

    static propTypes = {
        previousStep: PropTypes.func,
        nextStep: PropTypes.func,
        nextAction: PropTypes.func,
        isActive: PropTypes.bool,
        currentStep: PropTypes.number
    };

    static defaultProps = {
        previousStep: Function.prototype,
        nextStep: Function.prototype,
        nextAction: Function.prototype,
        isActive: false,
        currentStep: 1
    };

    shouldComponentUpdate (nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState) || !isEqual(this.context, nextContext);
    }

    onSubmit = (values) => {
        const stepQnA = initStepQnA[this.props.currentStep - 1];
        const { aboriginalTorres } = values;
        const answers = [];
        values.businessOwner && answers.push('As a business owner');
        values.farmerOrPrimaryProducer && answers.push('As a farmer or primary producer');
        values.propertyOwner && answers.push('As a property owner');
        values.renter && answers.push('As a renter');
        values.volunteerFirefighterOrSES && answers.push('As a Volunteer Firefighter/SES');
        values.noneOfTheAbove && answers.push('None of the above');

        stepQnA.questions[0].answers = collectAnswersFromOptions(impactOptions, values);
        // stepQnA.questions[1].answers = collectAnswersFromOptions(aboriginalTorresOptions, values);
        stepQnA.questions[1].answers = aboriginalTorres ? [aboriginalTorres] : [];

        this.props.nextAction('basicAssistance', values, this.props.currentStep, stepQnA);
        this.props.nextStep();
    };

    render () {
        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={this.onSubmit}>
                {({ values, handleChange, setFieldValue }) => (
                    <Form className={classNames('form form-basic-assistance', { hidden: !this.props.isActive })}>
                        <div className="form-title">About You</div>

                        <ExclusiveCheckboxGroup
                            id="impact"
                            legend="How have you been impacted?"
                            helpMessage="Please select all applicable."
                            options={impactOptions}
                            formik={{ values, handleChange, setFieldValue, initialValues }}
                        />
                        <WithStyledLegend
                            Component={FormRadioGroup}
                            id="aboriginalTorres"
                            name="aboriginalTorres"
                            legendText="Are you of Aboriginal and/or Torres Strait Islander origin?"
                            helpMessage="This can help us find additional support available to Aboriginal and Torres Strait Islander peoples. The information you provide cannot be disclosed to another agency without your consent, but may be used for anonymous reporting within Service NSW."
                            onChange={value => {
                                setFieldValue('aboriginalTorres', value);
                                handleChange(value);
                            }}
                            value={values.aboriginalTorres || ''}
                            options={aboriginalTorresOptions}
                            vertical
                        />

                        <FooterNav onBackPressed={this.props.previousStep} />
                    </Form>
                )}

            </Formik>
        );
    }
}
