import React from 'react';
import classNames from 'classnames';
import './CafUnavailablePage.scss';
import { InPageAlert } from '@snsw-gel/react';
import Header from '../../header/Header';

const CafUnavailablePage = () => (
    <div className={classNames('caf-unavailable', null)}>
        <Header title="COVID-19 Assistance Finder" />
        <div className="container">
            <InPageAlert variant='error' title='Page no longer exists'>
                <p>The COVID-19 Assistance Finder is no longer active.</p>
                <p>For information on services and support available, visit <a href={'https://www.service.nsw.gov.au/services/health-and-care#covid-19-support'}>health and care</a>.</p>
            </InPageAlert>
        </div>
    </div>
);

CafUnavailablePage.displayName = 'CafUnavailablePage';

export default CafUnavailablePage;
