import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { theme } from '@snsw-gel/react';
import { ThemeProvider } from 'styled-components';
import './QuestionAnswers.scss';
import Accordion from '../Accordion/Accordion';
import AccordionTile from '../Accordion/AccordionTile';

const QuestionAnswers = ({ questionAnswers }) => {
    const renderAnswers = answers => {
        if (answers.length === 0) {
            return 'No Answer';
        } else if (answers.length === 1) {
            return <p>{answers[0]}</p>;
        }

        return <ul style={{ listStyleType: 'initial' }}>
            {answers.map((answer, index) => {
                return <li key={index}>{answer}</li>;
            })}
        </ul>;
    };

    const renderQuestionAnswers = [];

    for (let i = 0; i < questionAnswers.length; i++) {
        const page = questionAnswers[parseInt(i)];
        renderQuestionAnswers.push(
            <AccordionTile title={page.heading} key={page.heading} >
                {page.questions.map(question => {
                    return (
                        <div key={question.text}>
                            <p style={{ fontWeight: 500 }}>{question.text}</p>
                            {renderAnswers(question.answers)}
                        </div>
                    );
                })}
            </AccordionTile>);
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="question-answers-container">
                <Accordion >
                    <h2>Your Answers</h2>
                    {renderQuestionAnswers}
                </Accordion>
            </div>
        </ThemeProvider>
    );
};

QuestionAnswers.defaultProps = {
    questionAnswers: []
};

QuestionAnswers.propTypes = {
    questionAnswers: PropTypes.array
};

export default memo(QuestionAnswers);
