import React, { Component } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Input } from '@snsw-gel/react';
import ReCAPTCHA from 'react-google-recaptcha';
import './ShareSummaryResults.scss';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { validateEmail } from '../../common/validate';

const recaptchaV2ClientKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_CLIENT_KEY;
const recaptchaRef = React.createRef();

class ShareSummaryResults extends Component {
    validate = (values) => {
        let errors = {};
        let hasError = false;

        if (!validateEmail(values.emailAddress.trim())) {
            errors.emailAddress = 'Please enter a valid email address';
            hasError = true;
        }

        if (!hasError) {
            errors = {};
        }
        return errors;
    };

    recaptchaV2SendShareSummaryResults = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        this.props.recaptchaV2SendShareSummaryResults(recaptchaValue);
    };

    recaptchaV3SendShareSummaryResults = async (values) => {
        try {
            values.token = await this.props.googleReCaptchaProps.executeRecaptcha('shareSummaryResults');
        } catch (e) {
            // we will not send to error, let us proceed with recaptcha v2.
        }
        this.props.recaptchaV3SendShareSummaryResults(values);
    };

    render () {
        return (
            <>
                <Formik
                    validate={this.validate}
                    initialValues={
                        {
                            emailAddress: ''
                        }
                    }
                    onSubmit={this.recaptchaV3SendShareSummaryResults}>
                    {({
                        values,
                        isSubmitting,
                        handleChange
                    }) => (
                        <Form>
                            <div className=" send-email">
                                <hr className="spacer"/>
                                <div className="line-spacing"></div>
                                <h3>Save your results</h3>
                                You can favourite this page or copy the web address to access your results later. Alternatively we can email a link to this page.

                                <div className="form__input-group ">
                                    <div style={{ marginTop: '-15px' }}>
                                        <Input
                                            helpMessage="We'll send a link to this results page. Your email address is not retained."
                                            id="emailAddress"
                                            label="Email address"
                                            name="emailAddress"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form__actions email-error">
                                        <button className="button button--tertiary" type="submit"
                                            disabled={isSubmitting}>
                                            <span className="hide-btn-text">Email Results</span>
                                        </button>
                                    </div>
                                </div>
                                <ErrorMessage className="form__error email-error" name={'emailAddress'}
                                    component={'div'} />
                                {/* The enterprise puzzle recaptcha doesn't work using inline JSX conditionally rendering. */}
                                <div style={{ display: this.props.showRecaptchaV2 ? 'block' : 'none' }}>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={recaptchaV2ClientKey}
                                        onChange={this.recaptchaV2SendShareSummaryResults}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}

ShareSummaryResults.propTypes = {
    recaptchaV3SendShareSummaryResults: PropTypes.func.isRequired,
    recaptchaV2SendShareSummaryResults: PropTypes.func.isRequired,
    showRecaptchaV2: PropTypes.bool.isRequired,
    googleReCaptchaProps: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(withGoogleReCaptcha(ShareSummaryResults));
